import {formdataify} from '../service'
export default ({ request }) => ({
  /**
   * 获取省份列表
   * @param data
   * @returns {*}
   * @constructor
   */
  GET_LEVEL1_LIST(params = {}) {
    // 接口请求
    return request({
      url: '/api/territory/getLevel1List',
      method: 'GET',
      params
    })
  },
  /**
   * 报名基本信息
   * @param data
   * @returns {*}
   * @constructor
   */
  JOB_SIGN_STEP1(data = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/saveStep1',
      method: 'POST',
      data:formdataify(data)
    })
  },
  /**
   * 报名基本信息2
   * @param data
   * @returns {*}
   * @constructor
   */
  JOB_SIGN_STEP2(data = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/saveStep2',
      method: 'POST',
      data:formdataify(data)
    })
  },
  /**
   * 报名
   * @param data
   * @returns {*}
   * @constructor
   */
  JOB_SIGN_ADD(data = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/save',
      method: 'POST',
      data:formdataify(data)
    })
  },

  /**
   * 获取简历1
   * @param data
   * @returns {*}
   * @constructor
   */
  SIGN_GET1 (params = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/getStep1',
      method: 'GET',
      params
    })
  },
  

  /**
   * 获取简历2
   * @param data
   * @returns {*}
   * @constructor
   */
  SIGN_GET2 (params = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/getStep2',
      method: 'GET',
      params
    })
  },
  

  /**
   * 获取简历
   * @param data
   * @returns {*}
   * @constructor
   */
  SIGN_GET (params = {}) {
    // 接口请求
    return request({
      url: '/api/job_sign/detail',
      method: 'GET',
      params
    })
  },
  
  /**
   * 投递岗位
   * @param data
   * @returns {*}
   * @constructor
   */
   RESUME_SEND (data = {}) {
    // 接口请求
    return request({
      url: '/api/resume/resume_send',
      method: 'POST',
      data:formdataify(data)
    })
  },

  /**
   * 上传文件
   * @param data
   * @returns {*}
   * @constructor
   */
  RESUME_FILE_SAVE (data = {}) {
    // 接口请求
    return request({
      headers: { 'Content-Type': 'multipart/form-data' },
      url: '/api/plupload/upload',
      method: 'POST',
      data:data
    })
  },

  /**
   * 七牛token
   * @param params
   * @returns {*}
   * @constructor
   */
   QINIU_TOKEN (params = {}) {
    // 接口请求
    return request({
      url: '/api/comm/qiniu_uptoken',
      method: 'GET',
      params:params
    })
  },
  /**
  * 获取公告列表
  * @param data
  * @returns {*}
  * @constructor
  */
   GET_NOTICE_LIST(params = {}) {
    // 接口请求
    return request({
      url: '/api/notice/index',
      method: 'GET',
      params
    })
  },

  /**
   * 支付
   * @param data
   * @returns {*}
   * @constructor
   */
  PAY_ALITRADE (data = {}) {
    // 接口请求
    return request({
      url: '/api/alipay/aliTradePay',
      method: 'POST',
      data:formdataify(data)
    })
  },


  /**
   * 支付尾款
   * @param data
   * @returns {*}
   * @constructor
   */
  PAY_ALITRADE_FINAL (data = {}) {
    // 接口请求
    return request({
      url: '/api/alipay/aliTradePayFinal',
      method: 'POST',
      data:formdataify(data)
    })
  },


  

  /**
  * 获取支付结果
  * @param data
  * @returns {*}
  * @constructor
  */
  GET_QUERY_ORDER(params = {}) {
    // 接口请求
    return request({
      url: '/api/alipay/queryOrder',
      method: 'GET',
      params
    })
  },


})

